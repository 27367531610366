#About{
    /* font-family: "Roboto", sans-serif; */
    
    /* display: grid; */
    height: 100vh;
    min-height: 100vh;
    /* grid-template-columns: 1fr; */
    /* grid-auto-rows: 50px auto 50px;
    grid-template-areas: "hd hd hd hd hd hd hd hd hd" "mn mn mn mn mn mn mn mn mn " "ft ft ft ft ft ft ft ft ft";
    gap: 0.5rem; */
}

#About main{
    text-align: center;
    padding: 10px;
    height: calc(100vh - 45px - 52px - 10px);
    display: flex;
    align-items: center;
    justify-content: center;
    /* grid-area: mn;
display: grid;
place-content: center; */
}

.about-about-text{
    display: grid;
    place-content: center;
    background: lightgray;
    min-height: 50vh;
}