#header {
    /* grid-area: hd; */
    background: var(--main-colour);
    color: #fff;
    text-align: center;
    /* display: flex;  */
    padding-inline: 1rem;
    padding: 10px;
    min-height: 52px;
}
#header .header-element-container{
   display: flex;
  
   align-items: center;
   justify-content: center;
   text-align: center;
   display: flex; 
   width: 100%;
   position: relative;
   
}

.header-hamburger-menu-button{
    display: none;
    cursor: pointer;
}

/* custom select */

#header select {
    /* box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5); */
    font-size: 1rem;
    padding: 0.5em 7em 0.5em 0.75em;
    background: #4d5061;
    color: white;
    border: 0;
    border-radius: 25px;
    font-family: "Roboto", sans-serif;
    
}


.header-custom-select {
    justify-self: start;
    position: absolute;
    left: 10px;
}

.header-custom-arrow {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    background: #3b3c47;
    height: 100%;
    width: 4em;
    pointer-events: none;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
}

.header-custom-arrow::before,
.header-custom-arrow::after {
    --size: 0.5em;
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    left: 50%;
    transform: translate(-50%, -50%);
}

.header-custom-arrow::before {
    border-left: var(--size) solid transparent;
    border-right: var(--size) solid transparent;
    border-bottom: var(--size) solid rgba(255, 255, 255, .5);
    top: 35%;
}

.header-custom-arrow::after {
    border-left: var(--size) solid transparent;
    border-right: var(--size) solid transparent;
    border-top: var(--size) solid rgba(255, 255, 255, .5);
    top: 65%;
}


/* end of custom select */




#header .header-element-container .nav-about {
    /* justify-self: end; */
}

.header-nav-about:hover {
    /* background: #4d5061; */
    color: #FED8B1;
    /* filter: brightness(50%); */
    cursor: pointer;
}
.header-nav-about {
    position: absolute;
    right: 10px;
}

.header-nav-logo {
    color: orange;
    cursor: pointer;
    margin-left: 280px;
}
.header-nav-logo:hover{
    cursor: pointer;
    color: #FED8B1;
}
.header-custom-select-mobile {
    display: none;
}

.margin-off {
    margin-left: 0 !important;
}

@media only screen and (max-width: 1024px) {
    .header-nav-logo {
        margin-left: 260px;
    }
}
@media only screen and (max-width: 900px) {
    .header-nav-logo {
        margin-left: 0px;
    }
    .header-hamburger-menu-button{
        display: block;
        position: absolute;
        left: 10px;
    }
    #header .header-element-container{
        /* display: grid;
        place-content: center;
        grid-template-rows: 1fr 1fr;
        z-index: 1;
        grid-template-columns: repeat(3, 1fr); */
    }
    .header-custom-select-mobile {
        display: block;
        position: relative;
        margin-top: 10px;
        width: 100%;
    }
    .header-custom-select-mobile select {
        width: 100%;
    }
    .header-custom-select {
        display: none;
    }
    #header .header-custom-select, #header .header-custom-select-mobile{
        grid-row-start: 3;
        grid-column-start: span 3;
        place-self: center;
    }
  }


  .header-element-container-left {
    justify-content: start !important;
  }