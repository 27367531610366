#Main{
    grid-area: mn;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content auto;
    gap: 0.5rem 0;
    width: 100%;
}

.main-menu-bar{
    display: flex;
    background: var(--main-colour);
    margin-top: 8px;
}

button {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden !important;
}

.main-menu-bar button{
  border-inline:1px solid #4d5061;
}

#Main .chevron-button{
max-width: 4%;
}
.chevron-button.right {
  padding: 0 !important;
  min-width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
} 
.chevron-button.left {
  padding: 0 !important;
  min-width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
} 

.main-box {
    color: #fff;
    font-size: 2rem;
    padding: 0;
    /* border-radius: 8px; */
    /* border: 1px linear-gradient( to bottom, #eee, #999) solid; */
    /* border: 1rem grey dashed; */
    min-height: 100%;
}

.main-box:first-child {
   
}

.main-box:nth-child(2) {
    
}





/* scroll within main div */
.main-scroll {
    overflow-y: scroll;
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    border: 2px solid #d7d7d7;
    position: relative;
    border-radius: 8px;
    /* height: 100vh;
    max-height: 100vh; */
    height: calc(100vh - 45px - 52px - 10px);
    margin-bottom: 8px;
    /* padding: 10px; */
}


.main-box2 {
    padding: 0;
    height: 100%;
}

.main-box2>iframe {
    border: none;
    overflow: hidden;
}

.main-box2>iframe::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
}


/* rounded slider */
 /* The switch - the box around the slider */
 .main-switch {
    position: sticky;
    display: inline-block;
    width: 60px;
    height: 34px;
    right: 6px;
    float: right;
    top: 8px;
    z-index: 1;
    opacity: 0.4;
  }

  .main-switch:hover {
    opacity: 1;
  }

  .main-switch.active {
  }

  .main-switch.active .main-slider {
    background-color: rgb(255, 214, 139);
  }

.tooltip {
  /* position: relative; */
  display: inline-block;
}
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
   
    /* Position the tooltip text - see examples below! */
    position: absolute;
    margin-left: -60px;
    margin-top: 38px;
    z-index: 1;
  }
  
  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }

  .main-switch.active .main-slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    right: 4px;
    bottom: 4px;
    margin-left: 24px;
    background-color: orange;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  /* Hide default HTML checkbox */
  .main-switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .main-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .main-slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  #Main input:checked + .slider {
    background-color: #2196F3;
  }
  
  #Main input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  #Main input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .main-slider.main-round {
    border-radius: 34px;
  }
  
  .main-slider.main-round:before {
    border-radius: 50%;
  } 

  .main-active{
    background-color: orange
  }

  .main-update-readme{
    margin-top: 1rem;
  }


@media only screen and (max-width: 900px) {
  button {
    font-size: 10px !important;
    padding: 10px 8px;
  }
}

  