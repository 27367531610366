@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --website-width:1250px;
  --main-colour: #242443;
  --icon-width: 28px;
}

/* debug mode */
div{
  /* border: 1px red solid; */
}

button{
  border: none;
  background: var(--main-colour);
color: white;
padding: 15px 32px;
text-align: center;
display: inline-block;
font-size: 1rem;
width: 100%;
cursor: pointer;
/* position: relative; */
}

button:active{
  background-color: orange;
}

button:hover{
  background-color: #FED8B1;
  /* translate: 10px 0 0; */
  position: relative;
  /* width: 24.2rem; */
}

