.footer {
    background: var(--main-colour);
    color: #fff;
    /* display: grid;
    grid-template-columns: 2fr 7fr;
    gap: 0.5rem; */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-width: 100%;
    padding: 10px;
    gap: 6px;
    margin-top: 10px;
    flex-direction: row;
}

.footer a{
    color: #fff;
}

.footer a:hover{
    color: #FED8B1;
}

.footer .footer-socials{
    /* background:#4d5061; */
    display: flex;
    padding-inline: 1rem;
    gap: 6px;

}

.footer .footer-socials .footer-social-icon{
    height: 100%;
    width: 25px;
    display: grid;
    place-content: center;
    margin-right: 0.2rem;
    color: #3b3c47;
}

.footer .footer-socials .footer-social-icon>*{
    height: 25px;
    width: 25px;
    /* place-self: center; */
}

.footer .footer-socials .footer-social-icon>*:hover{
    background: #FED8B1;
}


.footer .footer-copy {
    display: grid;
    text-align: center;
    place-content: center;
}


@media only screen and (max-width: 900px) {
    .footer {
        flex-direction: column;
    }
  }
  
    